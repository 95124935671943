import styled from "styled-components";
import * as anchor from "@project-serum/anchor";
import Button from "@material-ui/core/Button";
import { CandyMachineAccount } from "./candy-machine";
import { CircularProgress, Typography } from "@material-ui/core";
import { GatewayStatus, useGateway } from "@civic/solana-gateway-react";
import { useEffect, useState, useRef } from "react";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import {
  findGatewayToken,
  getGatewayTokenAddressForOwnerAndGatekeeperNetwork,
  onGatewayTokenChange,
  removeAccountChangeListener,
} from "@identity.com/solana-gateway-ts";

import { formatNumber } from "./utils";

export const CTAButton = styled(Button)`
  width: 100%;
  height: 60px;
  margin-top: 10px;
  margin-bottom: 5px;
  background: linear-gradient(180deg, #604ae5 0%, #813eee 100%);
  color: white;
  font-size: 16px;
  font-weight: 700;
  font-weight: bold !important;
  font-size: 20px !important;
`; // add your own styles here

const Wrapper = styled.div`
  display: flex;
  margin: 12px 0;
`;

const Quantity = styled.input`
  margin-right: 14px;
  font-size: 20px;
  width: 70%;
  padding: 10px 16px;
  border: 0;
  border-radius: 10px;
`;

export const MintButton = ({
  onMint,
  candyMachine,
  isMinting,
  setIsMinting,
  isActive,
  price,
}: {
  onMint: () => Promise<void>;
  candyMachine?: CandyMachineAccount;
  isMinting: boolean;
  setIsMinting: (val: boolean) => void;
  isActive: boolean;
  price: anchor.BN | undefined;
}) => {
  const wallet = useWallet();
  const connection = useConnection();
  const [verified, setVerified] = useState(false);
  const { requestGatewayToken, gatewayStatus } = useGateway();
  const [webSocketSubscriptionId, setWebSocketSubscriptionId] = useState(-1);
  const [clicked, setClicked] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [remainingToMint, setRemaining] = useState(0);

  const sum = price ? new anchor.BN(quantity).mul(price) : undefined;

  const getMintButtonContent = (sum: anchor.BN | undefined) => {
    if (candyMachine?.state.isSoldOut) {
      return "SOLD OUT";
    } else if (isMinting) {
      return <CircularProgress />;
    } else if (
      candyMachine?.state.isPresale ||
      candyMachine?.state.isWhitelistOnly
    ) {
      return "WHITELIST MINT";
    }

    if (sum) return `MINT FOR ◎${formatNumber.asNumber(sum)}`;

    return "MINT";
  };

  useEffect(() => {
    const mint = async () => {
      await removeAccountChangeListener(
        connection.connection,
        webSocketSubscriptionId
      );
      await onMint();

      setClicked(false);
      setVerified(false);
    };
    if (verified && clicked) {
      mint();
    }
  }, [
    verified,
    clicked,
    connection.connection,
    onMint,
    webSocketSubscriptionId,
  ]);

  const previousGatewayStatus = usePrevious(gatewayStatus);
  useEffect(() => {
    const fromStates = [
      GatewayStatus.NOT_REQUESTED,
      GatewayStatus.REFRESH_TOKEN_REQUIRED,
    ];
    const invalidToStates = [...fromStates, GatewayStatus.UNKNOWN];
    if (
      fromStates.find((state) => previousGatewayStatus === state) &&
      !invalidToStates.find((state) => gatewayStatus === state)
    ) {
      setIsMinting(true);
    }
    console.log("change: ", gatewayStatus);
  }, [setIsMinting, previousGatewayStatus, gatewayStatus]);

  return (
    <>
      <Wrapper>
        <Quantity
          type="number"
          value={quantity}
          onChange={(e) => setQuantity(Number(e.target.value))}
        />

        <CTAButton
          disabled={isMinting || !isActive}
          onClick={async () => {
            if (
              candyMachine?.state.isActive &&
              candyMachine?.state.gatekeeper
            ) {
              const network =
                candyMachine.state.gatekeeper.gatekeeperNetwork.toBase58();
              if (network === "ignREusXmGrscGNUesoU9mxfds9AiYTezUKex2PsZV6") {
                if (gatewayStatus === GatewayStatus.ACTIVE) {
                  await onMint();
                } else {
                  // setIsMinting(true);
                  await requestGatewayToken();
                  console.log("after: ", gatewayStatus);
                }
              } else if (
                network === "ttib7tuX8PTWPqFsmUFQTj78MbRhUmqxidJRDv4hRRE" ||
                network === "tibePmPaoTgrs929rWpu755EXaxC7M3SthVCf6GzjZt"
              ) {
                setClicked(true);
                const gatewayToken = await findGatewayToken(
                  connection.connection,
                  wallet.publicKey!,
                  candyMachine.state.gatekeeper.gatekeeperNetwork
                );

                if (gatewayToken?.isValid()) {
                  await onMint();
                } else {
                  window.open(
                    `https://verify.encore.fans/?gkNetwork=${network}`,
                    "_blank"
                  );

                  const gatewayTokenAddress =
                    await getGatewayTokenAddressForOwnerAndGatekeeperNetwork(
                      wallet.publicKey!,
                      candyMachine.state.gatekeeper.gatekeeperNetwork
                    );

                  setWebSocketSubscriptionId(
                    onGatewayTokenChange(
                      connection.connection,
                      gatewayTokenAddress,
                      () => setVerified(true),
                      "confirmed"
                    )
                  );
                }
              } else {
                setClicked(false);
                throw new Error(`Unknown Gatekeeper Network: ${network}`);
              }
            } else {
              for (let index = 0; index < quantity; index++) {
                setRemaining(quantity - index);
                await onMint();
              }

              setClicked(false);
            }
          }}
        >
          {getMintButtonContent(sum)}
        </CTAButton>
      </Wrapper>
      <Typography variant="body2" align="center">
        {remainingToMint > 0 ? (
          <>{remainingToMint} remaining to approve</>
        ) : (
          <>&nbsp;</>
        )}
      </Typography>
    </>
  );
};

function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}
